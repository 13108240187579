import React from 'react';
import styled from 'styled-components';
import Content from '../content';
import Header from './header';
import Stellen from '../news/stellen';
import { BigText } from '../common';
import Video from './video';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ChevronThinLeft, ChevronThinRight } from 'styled-icons/entypo/';

const Wrapper = styled.div`
    margin: auto;
`;

const MaxWidth = styled.div`
    max-width: 1200px;
    margin: auto;
`;

const StellenWrapper = styled.div`
    padding: 30px 10px;
`;

const SliderControl = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px 0 0 0;
`;

const Arrow = styled.div`
    background: #fff;
    border-radius: 50px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16), 0 3px 3px 0 rgba(0, 0, 0, 0.23);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 10px;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: #ddd;
    }
`;

const Index = ({ node }) => {
    const customeSlider = React.createRef();

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        initialSlide: 0,
    };

    const renderVideos = () => {
        const videos = node.frontmatter.videos;
        const videoArr = [];
        videos.forEach((video, index) => {
            videoArr.push(
                <Video video={video} index={index} key={video.youtubeUrl} />
            );
        });
        return videoArr;
    };

    return (
        <Wrapper>
            <MaxWidth>
                <Header
                    headerImg={node.frontmatter.headerImg}
                    slogan={node.frontmatter.slogan}
                />
                <Content fullWidth>{node.html}</Content>
            </MaxWidth>
            <Slider ref={customeSlider} {...settings}>
                {renderVideos()}
            </Slider>
            <SliderControl>
                <Arrow onClick={() => customeSlider.current.slickPrev()}>
                    <ChevronThinLeft size={24} color="#000" />
                </Arrow>
                <Arrow onClick={() => customeSlider.current.slickNext()}>
                    <ChevronThinRight size={24} color="#000" />
                </Arrow>
            </SliderControl>
            <MaxWidth>
                <Content fullWidth toMarkdown>
                    {node.frontmatter.textMd}
                </Content>
                <StellenWrapper>
                    <BigText>Offene Stellen</BigText>
                    <Stellen />
                </StellenWrapper>
            </MaxWidth>
        </Wrapper>
    );
};

export default Index;
