import React from 'react';
import styled from 'styled-components';
import { getSrc } from 'gatsby-plugin-image';

const Wrapper = styled.div`
    @media (min-width: 1350px) {
        padding-top: 30px;
    }
`;

const HeaderImg = styled.img`
    width: 100%;
    height: auto;
    filter: saturate(0.6);
`;
const Slogan = styled.h1`
    text-align: center;
    padding: 30px 20px 10px 20px;
    color: ${({ theme }) => theme.colors.accent};
    font-size: 2em;
`;

const Header = ({ headerImg, slogan }) => {
    return (
        <Wrapper>
            <HeaderImg src={getSrc(headerImg)} alt="Headerbild" />
            <Slogan>{slogan}</Slogan>
        </Wrapper>
    );
};

export default Header;
