import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 20px;
    background: #111;
`;
const VideoWrapper = styled.div`
    padding: 5px;
    background: #111;
    max-width: 1000px;
    margin: auto;
`;

const VideoContainer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
`;

const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const VideoInfo = styled.div`
    color: #fff;
    font-size: 1.3em;
    padding: 10px 5px;
    max-width: 1000px;
    margin: auto;
`;
const Elem = styled.h3`
    font-weight: 400;
`;

const Bold = styled.span`
    font-weight: 600;
`;

const RenderVideo = ({ video, index }) => {
    const channelID = 'UCpjAsu9HxDHx47arM2dObHQ';

    const renderVideo = () => {
        return (
            <VideoContainer>
                <Iframe
                    cid={channelID}
                    src={`https://www.youtube-nocookie.com/embed/${video.youtubeUrl}?rel=0`}
                    frameborder="0"
                    allowFullScreen
                ></Iframe>
            </VideoContainer>
        );
    };

    return (
        <Wrapper>
            <VideoInfo>
                <Elem>
                    <Bold>{video.name}</Bold>
                </Elem>
                <Elem>
                    <Bold>Im Verein seit:</Bold> {video.vereinSeit}
                </Elem>
                <Elem>
                    <Bold>Abteilung:</Bold> {video.aufgabe}
                </Elem>
            </VideoInfo>
            <VideoWrapper>{renderVideo()}</VideoWrapper>
        </Wrapper>
    );
};

export default RenderVideo;
