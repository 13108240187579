import * as React from 'react';
import { graphql } from 'gatsby';
import SiteWrapper from '../components/siteWrapper';
import MitmachenComponent from '../components/mitmachen/';

const Mitmachen = (props) => {
    const node = props.data.allFile.edges[0].node.childMarkdownRemark;

    return (
        <SiteWrapper
            title="Mitmachen - SV Kappel"
            description="Du hast uns gerade noch gefehlt!"
        >
            <MitmachenComponent node={node} />
        </SiteWrapper>
    );
};

export default Mitmachen;

export const query = graphql`
    query MitmachenQuery {
        allFile(
            filter: {
                sourceInstanceName: { eq: "sites" }
                name: { eq: "mitmachen" }
            }
        ) {
            edges {
                node {
                    childMarkdownRemark {
                        html
                        frontmatter {
                            slogan
                            headerImg {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                            videos {
                                youtubeUrl
                                vereinSeit
                                aufgabe
                                name
                            }
                            textMd
                        }
                    }
                }
            }
        }
    }
`;
